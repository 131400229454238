<!--
 * @Description:
 * @Version: 0.0.1
 * @Autor: Silence
 * @Date: 2023-04-25 23:51:20
 * @LastEditors:
 * @LastEditTime: 2023-04-25 23:51:20
-->
<template>
  <el-form v-loading="loading" label-width="140px" ref="formData">
    <el-form-item label="评分验证规则开关">
      <el-tooltip
        :content="value ? '评分验证规则开' : '评分验证规则关'"
        placement="top"
      >
        <el-switch v-model="value" :active-value="true" :inactive-value="false">
        </el-switch>
      </el-tooltip>
    </el-form-item>
    <el-form-item>
      <el-button type="success" @click="handleSave">保 存</el-button>
    </el-form-item>
  </el-form>
</template>
<script>
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      loading: false,
      value: false,
    };
  },
  created() {},
  mounted() {
    this.getSystem();
  },
  methods: {
    /**
     *  @actions:  获取系统配置文件
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/7/23 14:15
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    getSystem() {
      this.loading = true;
      this.$cloud
        .get("setting/get", { key: "ruleSwitch" })
        .then((res) => {
          this.loading = false;
          try {
            this.value = parseInt(res) === 1;
          } catch (e) {
            this.value = false;
          }
        })
        .catch((err) => {
          this.loading = false;
          this.$message.error(err);
        });
    },

    /**
     *  @actions:   保存
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/7/21 0:24
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handleSave() {
      this.loading = true;
      this.$cloud
        .post("setting/set", {
          key: "ruleSwitch",
          value: this.value ? 1 : 0,
        })
        .then(() => {
          this.loading = false;
          this.$message.success("保存成功");
        })
        .catch((err) => {
          this.loading = false;
          this.$message.error(err);
        });
    },
  },
};
</script>
<style lang="scss" scoped></style>
