<!--
 * ======================================
 * 说明： 系统设置
 * 作者： Silence
 * 文件： index.vue
 * 日期： 2023/7/26 23:58
 * 邮箱： 1106194754@qq.com
 * ======================================
-->
<template>
  <el-tabs v-model="activeName" type="card">
    <el-tab-pane label="系统设置" name="system">
      <system />
    </el-tab-pane>
    <el-tab-pane label="评分开关" name="ruleSwitch">
      <rule-switch/>
    </el-tab-pane>
    <el-tab-pane label="评分规则" name="scoreRules">
      <score-rules/>
    </el-tab-pane>
  </el-tabs>
</template>
<script>
import system from "./system.vue";
import scoreRules from "./scoreRules.vue";
import ruleSwitch from "./ruleSwitch.vue";
export default {
  name: "",
  components: { system, scoreRules,ruleSwitch },
  props: {},
  data() {
    return {
      activeName: "system",
    };
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped></style>
