<!--
 * @Description:
 * @Version: 0.0.1
 * @Autor: Silence
 * @Date: 2023-04-25 23:51:20
 * @LastEditors:
 * @LastEditTime: 2023-04-25 23:51:20
-->
<template>
  <el-form
    v-loading="loading"
    :model="formData"
    label-width="120px"
    ref="formData"
    :rules="rule"
  >
    <el-form-item label="系统logo" prop="logo">
      <el-upload
        :limit="1"
        action="#"
        list-type="picture-card"
        :show-file-list="false"
        :http-request="onUploadPicture"
      >
        <img v-if="formData.logo" :src="formData.logo" class="avatar" />
        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
      </el-upload>
    </el-form-item>
    <el-form-item label="系统名称" prop="title">
      <el-input v-model="formData.title" style="width: 400px"></el-input>
    </el-form-item>
    <el-form-item>
      <el-button type="success" @click="handleSave">保 存</el-button>
    </el-form-item>
  </el-form>
</template>
<script>
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      loading: false,
      formData: {
        logo: "",
        title: "",
      },
      rule: {
        logo: [
          {
            required: true,
            message: "请上传logo",
            trigger: "blur",
          },
        ],
        title: [
          {
            required: true,
            message: "请填写标题",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {},
  mounted() {
    this.getSystem();
  },
  methods: {
    /**
     *  @actions:  上传logo
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/7/21 0:22
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    onUploadPicture(file) {
      let params = {
        file: file.file,
      };
      this.loading = true;
      this.$cloud
        .upload("files/upload", params)
        .then((res) => {
          this.loading = false;
          this.formData.logo = res.url;
        })
        .catch((err) => {
          this.loading = false;
          this.$message.error(err);
        });
    },

    /**
     *  @actions:  获取系统配置文件
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/7/23 14:15
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    getSystem() {
      this.loading = true;
      this.$cloud
        .get("setting/get", { key: "logo" })
        .then((res) => {
          this.loading = false;
          try {
            this.formData = JSON.parse(res);
          } catch (e) {
            this.formData = {};
          }
        })
        .catch((err) => {
          this.loading = false;
          this.$message.error(err);
        });
    },

    /**
     *  @actions:   保存
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/7/21 0:24
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handleSave() {
      this.$refs["formData"].validate((valid) => {
        if (valid) {
          let params = {
            key: "logo",
            value: JSON.stringify(this.formData),
          };
          this.$cloud
            .post("setting/set", params)
            .then(() => {
              this.$message.success("保存成功");
            })
            .catch((err) => {
              this.$message.error(err);
            });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
