import { render, staticRenderFns } from "./ruleSwitch.vue?vue&type=template&id=63ce3887&scoped=true"
import script from "./ruleSwitch.vue?vue&type=script&lang=js"
export * from "./ruleSwitch.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63ce3887",
  null
  
)

export default component.exports