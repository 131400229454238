<!--
 * ======================================
 * 说明： 评分规则
 * 作者： Silence
 * 文件： scoreRules.vue
 * 日期： 2023/7/26 23:58
 * 邮箱： 1106194754@qq.com
 * ======================================
-->
<template>
  <div v-loading="loading">
    <el-button type="success" size="mini" class="mb-2" @click="handleSave"
      >保存</el-button
    >
    <div class="border p-2">
      <el-row class="border">
        <el-col :span="5" class="p-2">分值</el-col>
        <el-col :span="16" class="p-2">
          <el-row>
            <el-col :span="16"> 范围 </el-col>
            <el-col :span="8" class="pl-8"> 比率 </el-col>
          </el-row>
        </el-col>
      </el-row>
      <div class="rule-scoll border-r border-b">
        <el-row
          :class="`border ${index === 0 ? 'border-t-0' : ''} border-b-0`"
          v-for="(item, index) in config"
          :key="index"
        >
          <el-col :span="5" class="p-2">
            <el-input-number
              :min="0"
              class="w-full"
              v-model="item.id"
            ></el-input-number>
            <el-button
              @click="handleAddScore(index)"
              class="mt-2"
              type="primary"
              icon="el-icon-plus"
              circle
            ></el-button>
            <el-button
              @click="handleDelScore(index)"
              class="mt-2"
              type="danger"
              icon="el-icon-delete"
              circle
            ></el-button>
          </el-col>
          <el-col :span="16" class="border-l">
            <el-row v-for="(t, i) in item.value" :key="i" class="p-2">
              <el-col :span="16">
                <el-col :span="11">
                  <el-input-number
                    :min="0"
                    v-model="t.min"
                    class="w-full"
                  ></el-input-number>
                </el-col>
                <el-col :span="2" class="text-center"> - </el-col>
                <el-col :span="11">
                  <el-input-number
                    :min="0"
                    v-model="t.max"
                    class="w-full"
                  ></el-input-number>
                </el-col>
              </el-col>
              <el-col :span="8" class="pl-8">
                <el-input-number
                  :min="0"
                  :max="1"
                  :step="0.01"
                  v-model="t.ratio"
                  class="w-full"
                ></el-input-number>
              </el-col>
            </el-row>
            <el-row class="p-2">
              <el-button size="mini" @click="handleAddRule(item.value)"
                >添加规则</el-button
              >
              <el-button size="mini" @click="handleDelRule(item.value)"
                >删除规则</el-button
              >
            </el-row>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      key: "rule",
      loading: false,
      config: [],
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.getRules();
  },
  methods: {
    // 获取评分规则
    getRules() {
      // 获取评分规则
      this.loading = true;
      this.$cloud
        .get("setting/get", {
          key: this.key,
        })
        .then((res) => {
          this.loading = false;
          this.config = [];
          try {
            let obj = JSON.parse(res);
            Object.keys(obj).map((key) => {
              this.config.push({
                id: Number(key),
                value: obj[key],
              });
            });
          } catch (e) {
            this.config = [];
          }
          console.log("config::", this.config);
        })
        .catch((err) => {
          this.loading = false;
          this.$message.error(err);
        });
    },

    // 添加分值项
    handleAddScore(index) {
      this.config.splice(index, 0, {
        id: 0,
        value: [
          {
            min: 0,
            max: 0,
            ratio: 0,
          },
        ],
      });
    },

    // 删除分值项
    handleDelScore(index) {
      this.config.splice(index, 1);
    },

    // 添加规则
    handleAddRule(items) {
      items.splice(items.length + 1, 0, {
        min: 0,
        max: 0,
        ratio: 0,
      });
    },

    // 删除规则
    handleDelRule(items) {
      items.splice(items.length - 1, 1);
    },

    // 保存规则
    handleSave() {
      let obj = {};
      this.config.map((item) => {
        this.$set(obj, item.id, item.value);
      });
      let value = "{}";
      try {
        value = JSON.stringify(obj);
      } catch (e) {
        value = "{}";
      }
      this.loading = true;
      this.$cloud
        .post("setting/set", {
          key: this.key,
          value: value,
        })
        .then(() => {
          this.loading = false;
          this.$message.success("保存成功");
        })
        .catch((err) => {
          this.loading = false;
          this.$message.error(err);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.rule-scoll {
  overflow: hidden;
  overflow-y: auto;
  height: calc(100vh - 260px);
}
</style>
